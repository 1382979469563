import React from "react"
import { Redirect, BrowserRouter as Router, Route } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"

import EditNote from "./edit"
import Favorites from "./favorites"
import Home from "./home"
import Layout from "../components/Layout"
import MyNotes from "./mynotes"
import NewNote from './new'
import NotePage from './note'
import SignIn from './signin'
import SignUp from "./signUp"

import { IS_LOGGED_IN } from "../gql/query"

const PrivateRoute = ({ component: Component, ...rest}) => {
  const { data, error, loading } = useQuery(IS_LOGGED_IN)

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>
  console.log("==data", data)
  console.log("==API_URI", process.env.API_URI)

  return (
    <Route
      {...rest}
      render={props =>
        data.isLoggedIn === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

const Pages = () => (
  <Router>
    <Layout>
      <Route exact path="/" component={Home} />
      {/* <PrivateRoute path="/mynotes" component={MyNotes} />
      <PrivateRoute path="/favorites" component={Favorites} />
      <Route exact path="/new" component={NewNote} />
      <PrivateRoute path="/edit/:id" component={EditNote} />
      <Route path="/note/:id" component={NotePage} />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} /> */}
    </Layout>
  </Router>
)

export default Pages
