import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import NoteForm from "../components/NoteForm"
import { GET_NOTES, NEW_NOTE } from "../gql/query"

const NewNote = props => {
  useEffect(() => {
    document.title = "New Note- Notedly"
  })

  const [newNote, { loading, error }] = useMutation(NEW_NOTE, {
    refetchQueries: [{ query: GET_NOTES }],
    onCompleted: data => {
      props.history.push(`/note/${data.newNote.id}`)
    }
  })

  return (
    <React.Fragment>
      { loading && <p>Loading...</p> }
      { error && <p>Error saving note...</p> }
      <NoteForm action={newNote} />
    </React.Fragment>
  )
}

export default NewNote
