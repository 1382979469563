import React from "react"

import { GET_ME, GET_NOTE } from "../gql/query"
import { useMutation, useQuery } from "@apollo/client"

import { EDIT_NOTE } from "../gql/mutation"
import NoteForm from "../components/NoteForm"

const EditNote = props => {
  const id = props.match.params.id
  const { loading, error, data } = useQuery(GET_NOTE, { variables: { id }})
  const { data: userData, loading: userLoading } = useQuery(GET_ME)
  const [editNote] = useMutation(EDIT_NOTE, {
    variables: {
      id
    },
    onCompleted: () => {
      props.history.push(`/note/${id}`)
    }
  })
  
  if (loading || userLoading) return <p>Loading...</p>

  if (error) return <p>Error! Note not found</p>

  if (userData.me.id !== data.note.author.id) {
    return <p>You do not have access to edit this note</p>
  } else {
    return <NoteForm action={editNote} content={data.note.content} />
  }
}

export default EditNote
